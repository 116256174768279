<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">RCM INCENTIVES</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            v-model="month_of"
            class="mx-3"
            :items="month_of_items"
            item-value="month_of"
            item-text="month_of"
            label="Month Of"
            required
            @change="monthly_remittances"
            dense
          ></v-select>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            v-model="type_of_officer"
            class="mx-3"
            :items="['REGIONAL CHAPEL MANAGER','CHAPEL DIRECTOR']"
            label="Type of Officer"
            required
            @change="monthly_remittances"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">ID</th>
              <th class="text-center text-uppercase">RCM Name</th>
              <th class="text-center text-uppercase">Region Service Collected</th>
              <th class="text-center text-uppercase">Region Service Collected Percentage</th>
              <th class="text-center text-uppercase">Region Service Collected Incentives</th>
              <th class="text-center text-uppercase">Goodlife Cares Count</th>
              <th class="text-center text-uppercase">Goodlife Cares Quota</th>
              <th class="text-center text-uppercase">Goodlife Cares Quota Incentives</th>
              <th class="text-center text-uppercase">Direct Service Quota</th>
              <th class="text-center text-uppercase">Direct Service Quota Per Branch</th>
              <th class="text-center text-uppercase">Direct Service Quota Incentives</th>
              <th class="text-center text-uppercase">Region Quota Service Count</th>
              <th class="text-center text-uppercase">Region Quota</th>
              <th class="text-center text-uppercase">Region Quota Incentives</th>
              <th class="text-center text-uppercase">Total</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in chapel_manager_incentives_items" :key="item.id">
              <td>
                <v-icon
                  class="mr-2"
                  color="warning"
                  @click="print_chapel_manager_incetives(item)"
                >
                  {{icons.mdiPrinter}}
                </v-icon></td>
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.name }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.service_collected) }}
              </td>
              <td class="text-center">
                {{ parseFloat(item.service_collected_percentage)*100+'%' }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.service_collected_incentives) }}
              </td>
              <td class="text-center">
                {{ item.cares_quota }}
              </td>
              <td class="text-center">
                {{ item.cares_quota_percentage }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.cares_quota_incentives) }}
              </td>
              <td class="text-center">
                {{ item.direct_service_quota }}
              </td>
              <td class="text-center">
                {{ item.direct_service_quota_number }}
              </td>

              <td class="text-center">
                {{ formatPrice(item.direct_service_quota_incentives) }}
              </td>
              <td class="text-center">
                {{ item.region_service_quota }}
              </td>
              <td class="text-center">
                {{ item.region_service_quota_percentage }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.region_service_quota_incentives) }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.total_incentives) }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiPrinter} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      month_of: '',
      type_of_officer: '',
      month_of_items: [],

      chapel_manager_incentives_items: [],
      is_deleting: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiPrinter,
        },
      }
    },
    created() {
      this.list_of_month_for_officer_incentives()
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch_id', 'month_start', 'month_end', 'user_id', 'branch', 'branch_address', 'branch_contact_no']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months', ['list_of_month_for_officer_incentives']),
      ...mapActions('officers', ['rcm_incentives']),
      monthly_remittances() {
        const data = new FormData()
        data.append('month_of', this.month_of)
        data.append('type_of_officer', this.type_of_officer)
        data.append('is_start_new_month', 0)
        this.rcm_incentives(data)
          .then(response => {
            this.chapel_manager_incentives_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      print_chapel_manager_incetives(item) {
        var imgData = this.company_logo
        var month = this.month_of

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: this.type_of_officer+' INCENTIVES', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            ' ',
            {text: 'MONTH OF: ' + month},
            {text: 'NAME: ' + item.name },
            ' ',
            {
              text: 'REGION SERVICE COLLECTED: ' + (item.service_collected / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              style: {color: 'blue'}
            },
            {text: 'REGION SERVICE COLLECTED PERCENTAGE: ' + parseFloat(item.service_collected_percentage)*100+'%'},
            {
              text: 'REGION SERVICE COLLECTED INCENTIVES: ' + (item.service_collected_incentives / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              style: {color: 'green'}
            },
            ' ',
            {text: 'GOODLLIFE CARES COUNT: ' + item.cares_quota ,
              style: {color: 'blue'}},
            {text: 'GOODLIFE CARES QUOTA: ' + item.cares_quota_percentage},
            {
              text: 'GOODLIFE CARES QUOTA INCENTIVES: ' + (item.cares_quota_incentives / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              style: {color: 'green'}
            },
            ' ',
            {text: 'DIRECT SERVICE QUOTA: ' + item.direct_service_quota  ,
              style: {color: 'blue'}},
            {text: 'DIRECT SERVICE QUOTA PER BRANCH: ' + item.direct_service_quota_number },
            {
              text: 'DIRECT SERVICE QUOTA INCENTIVES: ' + (item.direct_service_quota_incentives / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              style: {color: 'green'}
            },
            ' ',
            {text: 'REGION QUOTA SERVICE COUNT: ' + item.region_service_quota   ,
              style: {color: 'blue'}},
            {text: 'REGION QUOTA: ' + item.region_service_quota_percentage  },
            {
              text: 'REGION QUOTA INCENTIVES: ' + (item.region_service_quota_incentives / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              style: {color: 'green'}
            },
            ' ',
            {
              text: 'TOTAL INCENTIVES: ' + (item.total_incentives / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              style: {color: 'red'}
            },
            ' ',
            'DEDUCTIONS',
            ' ',
            '__________________________________',
            ' ',
            '__________________________________',
            ' ',
            '__________________________________',
            ' ',
            '__________________________________',
            ' ',
            'NET INCOME',
            ' ',
            '__________________________________',
          ],
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 10
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },

          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    },
  }
</script>
